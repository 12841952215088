<template>
  <p class="text-xl my-3">Apps</p>
  <ul class="border rounded-lg p-3">
    <li
      v-for="(app, i) in allApps"
      :key="app.app_name"
      class="border-b border-b-2 p-2 border-grey-400"
    >
      <div class="my-1">
        <chevron-down-icon
          v-if="isExpanded[i]"
          @click="isExpanded[i] = false"
          class="h-4 inline-block text-gray-500 mr-2 cursor-pointer"
          aria-hidden="true"
        />
        <chevron-right-icon
          v-else
          @click="isExpanded[i] = true"
          class="h-4 inline-block text-gray-500 mr-2 cursor-pointer"
          aria-hidden="true"
        />
        <span> {{ app.app_name }} </span>
        <span
          class="text-sm border rounded-md p-1 ml-2 bg-blue-100 text-blue-800"
        >
          {{ app.app_type }}
        </span>
      </div>

      <ul
        v-show="isExpanded[i]"
        v-for="release in app.releases"
        :key="release.version"
        class="text-sm ml-10 pt-2"
      >
        <li>{{ release.version }} - {{ release.files }}</li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/vue/outline";

export default defineComponent({
  name: "Apps",
  components: { ChevronRightIcon, ChevronDownIcon },
  data() {
    return {
      isExpanded: [],
    };
  },
  computed: {
    ...mapState(["allApps"]),
  },
});
</script>

<style scoped></style>
