<template>
  <div style="height: calc(100vh - 60px); margin-top: 50%">
    <div v-if="error" class="alert border-red-700 bg-red-300 text-red-700">
      {{ error }}
    </div>
    <div
      v-if="message"
      class="alert border-green-900 bg-green-200 text-green-900"
    >
      {{ message }}
    </div>
    <form class="w-80 p-3 border rounded-lg mx-auto">
      <label for="pass" class="block w-full">
        <span class="font-semibold">dokk.</span>
        <span class="text-gray-400 text-sm float-right">© Reope AS 2021</span>
      </label>
      <input
        id="email"
        placeholder="Email"
        autocomplete="email"
        v-model="form.email"
        class="p-2 border w-full block rounded-md my-2"
      />
      <div v-show="!isForgot">
        <input
          id="pass"
          placeholder="Password"
          :type="showPassword ? 'text' : 'password'"
          autocomplete="password"
          v-model="form.password"
          class="p-2 border w-full block rounded-md my-2"
        />
        <input
          type="checkbox"
          v-model="showPassword"
          id="show-pass"
          class="ml-2"
        />
        <label for="show-pass" class="text-sm ml-1">Show Password</label>
        <button
          class="border bg-green-600 text-white rounded-md p-2 w-full mt-1"
          @click="submit"
          type="button"
        >
          Enter
        </button>
        <button
          class="text-blue-700 text-xs w-full mt-1 text-left"
          @click="isForgot = true"
          type="button"
        >
          I forgot my password!
        </button>
      </div>
      <button
        v-show="isForgot"
        class="border bg-green-600 text-white rounded-md p-2 w-full mt-1"
        @click="resetPassword"
        type="button"
      >
        Reset Password
      </button>
    </form>
  </div>
</template>

<script lang="ts">
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "@firebase/auth";
import { defineComponent } from "vue";

import { auth } from "@/utils/firebase-app";
import { Nullable } from "@/utils/ts-utils";

export default defineComponent({
  data() {
    return {
      isForgot: false,
      showPassword: false,
      form: {
        email: "",
        password: "",
      },
      error: null,
      message: null as Nullable<string>,
    };
  },
  methods: {
    submit() {
      signInWithEmailAndPassword(
        auth,
        this.form.email,
        this.form.password
      ).catch((err: any) => {
        this.error = err.message;
        setTimeout(() => (this.error = null), 2500);
      });
    },
    async resetPassword(): Promise<void> {
      this.isForgot = false;
      sendPasswordResetEmail(auth, this.form.email)
        .then(() => {
          this.message =
            "You should receive an email to reset your password soon!";
        })
        .catch((err) => {
          this.error = err.message;
          setTimeout(() => (this.error = null), 2500);
        });

      setTimeout(() => (this.message = null), 2500);
    },
  },
});
</script>
<style lang="postcss">
.alert {
  @apply mx-auto  my-2  p-2  w-80 border-t-2;
}
</style>
