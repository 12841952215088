<template>
  <div class="border-b-2 px-2 py-3">
    <a class="text-blue-400 text-lg font-semibold" href="/">dokk.</a>
    <div class="float-right" v-if="isAuthenticated">
      <button
        class="text-blue-400 font-semibold mr-2"
        @click="$router.push('/')"
      >
        Clients
      </button>
      <button
        class="text-blue-400 font-semibold mr-2"
        @click="$router.push('/apps')"
      >
        Apps
      </button>
      <button class="button-text" @click="logout">Logout</button>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { auth } from "@/utils/firebase-app";

export default defineComponent({
  name: "TopBar",
  methods: {
    logout() {
      auth.signOut();
      this.$router.push("/login");
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
});
</script>
