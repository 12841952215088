import store from "@/store";

export async function invokeWithConfirmation<T>(
  message: string,
  func: () => T,
  description = "Are you sure?"
): Promise<void> {
  const func1 = () => {
    const result = func();
    store.commit("RESET_CONFIRM_ACTION");
    return result;
  };
  return store.commit("SET_CONFIRM_ACTION", {
    message,
    description,
    func: func1,
  });
}
export async function invokeWithErrorCatching<T>(
  func: () => T
): Promise<void | T> {
  try {
    return await func();
  } catch (e) {
    store.commit("SET_ERROR_ACTION", {
      message: "Error",
      description: e,
      func,
    });
  }
}
