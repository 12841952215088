<template>
  <div>
    <form
      class="p-3 border-blue-300 border-2 mt-1 rounded-md mb-2"
      @submit.prevent="addConfig"
    >
      <p class="text-xs absolute -mt-2 ml-1 bg-white" style="z-index: 2">
        New Client
      </p>
      <input
        type="text"
        v-model="newConfig"
        class="border rounded border-blue-300 p-1 outline-none"
      />
      <button
        class="
          bg-gray-300
          p-1
          rounded
          border
          mx-1
          focus:outline-none
          hover:bg-gray-400
        "
        type="submit"
      >
        Add
      </button>
      <button
        class="
          p-1
          rounded
          border border-gray-300
          mx-1
          focus:outline-none
          hover:bg-gray-100
        "
        @click="newConfig = ''"
        type="reset"
      >
        Clear
      </button>
    </form>
    <p class="text-xl my-3">Clients</p>
    <config-item
      v-for="c in configs"
      :config="c"
      :config-name="c.name"
      :config-id="c.id"
      :apps="c.apps"
      :is-active="c.is_active"
      :key="c.id"
      @update-app="updateApp"
      @update-configs="refreshConfigs"
      @update-config="updateConfig"
    >
    </config-item>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  addClientApp,
  newConfig,
  removeClientApp,
  updateConfig,
} from "@/api/hasura";
import ConfigItem from "@/components/ConfigItem.vue";
import { ClientConfig } from "@/models/client";
import { mapState } from "vuex";
import store from "@/store";
import { DokkApp } from "@/models/dokk-app";

export default defineComponent({
  name: "Home",
  components: { ConfigItem },
  data() {
    return {
      newConfig: "",
    };
  },
  computed: {
    ...mapState(["allApps", "configs"]),
  },
  methods: {
    async updateApp({ appName, configId, value }: any) {
      const c = this.configs.find((x: ClientConfig) => x.id === configId);
      let isDelete = false;
      if (!c) {
        return;
      }
      const index = c.apps.findIndex((x: DokkApp) => x.app_name === appName);

      if (value && index === -1) {
        c.apps.push({ app_name: appName });
      }

      if (!value && index !== -1) {
        isDelete = true;
        c.apps.splice(index, 1);
      }

      try {
        if (isDelete) {
          await removeClientApp(configId, appName);
        } else {
          await addClientApp(configId, appName);
        }

        await this.refreshConfigs();
      } catch (e) {
        console.error(e);
      }
    },
    async updateConfig(config: ClientConfig) {
      try {
        await updateConfig(config);
      } catch (e) {
        console.error(e);
      }
      await this.refreshConfigs();
    },
    async addConfig() {
      let c;
      try {
        c = await newConfig(this.newConfig);
      } catch (e) {
        console.error(e);
        return;
      }
      await store.dispatch("init");
      this.newConfig = "";
    },
    async refreshConfigs() {
      await store.dispatch("loadConfigs");
    },
  },
});
</script>
