
import TopBar from "@/components/TopBar.vue";
import Popup from "@/components/Popup.vue";

import { defineComponent } from "vue";
import { mapState } from "vuex";
import store from "@/store";
export default defineComponent({
  components: { Popup, TopBar },
  computed: {
    ...mapState(["confirmAction"]),
  },
  methods: {
    closeModal() {
      store.commit("RESET_CONFIRM_ACTION");
    },
  },
});
