import { DokkApp } from "@/models/dokk-app";
import { ClientConfig, generateId } from "@/models/client";
import { callHasura } from "@/api/hasura/provider";
import { gql } from "@/api/hasura/graphql-utils";

export async function loadApps(): Promise<DokkApp[]> {
  const query = gql`
    query loadApps {
      app {
        app_name
        app_type
        releases {
          files
          version
        }
      }
    }
  `;
  const transform = (data: any) => {
    return data.app as DokkApp[];
  };
  return callHasura({ query, transform });
}

export async function loadConfigs(): Promise<ClientConfig[]> {
  const query = gql`
    query loadConfigs {
      client(order_by: { client_id: asc }) {
        client_id
        client_name
        is_active
        update_channel
        client_apps {
          app_name
        }
      }
    }
  `;
  const transform = (data: any) => {
    return data.client.map(
      (x: any): ClientConfig => ({
        name: x.client_name,
        id: x.client_id,
        apps: x.client_apps || [],
        is_active: x.is_active,
        update_channel: x.update_channel,
      })
    );
  };
  return callHasura({ query, transform });
}

export async function addClientApp(
  client_id: string,
  app_name: string
): Promise<void> {
  const query = gql`
    mutation addClientApp($client_id: String!, $app_name: String!) {
      insert_client_app_one(
        object: { client_id: $client_id, app_name: $app_name }
      ) {
        client_id
      }
    }
  `;
  const variables = { client_id, app_name };
  const transform = () => {
    /**/
  };
  return callHasura({ query, variables, transform });
}

export async function removeClientApp(
  client_id: string,
  app_name: string
): Promise<void> {
  const query = gql`
    mutation addClientApp($client_id: String!, $app_name: String!) {
      delete_client_app_by_pk(client_id: $client_id, app_name: $app_name) {
        client_id
      }
    }
  `;
  const variables = { client_id, app_name };
  const transform = () => {
    /**/
  };
  return callHasura({ query, variables, transform });
}

export async function updateConfig(config: ClientConfig): Promise<void> {
  const query = gql`
    mutation updateClient($data: client_set_input!, $client_id: String!) {
      update_client_by_pk(pk_columns: { client_id: $client_id }, _set: $data) {
        client_id
        client_name
        is_active
        update_channel
        client_apps {
          app_name
        }
      }
    }
  `;
  const variables = {
    client_id: config.id,
    data: {
      is_active: config.is_active,
      update_channel: config.update_channel,
    },
  };

  return callHasura({
    query,
    variables,
    transform: () => {
      /**/
    },
  });
}

export async function newConfig(name: string): Promise<ClientConfig> {
  const query = gql`
    mutation newConfig($name: String!, $id: String!) {
      insert_client_one(object: { client_name: $name, client_id: $id }) {
        client_id
        client_name
        is_active
        update_channel
      }
    }
  `;
  const variables = { name, id: generateId(name) };
  const transform = (data: any): ClientConfig => {
    const client = data.insert_client_one;
    /**/
    return {
      name: client.client_name,
      apps: [],
      id: client.client_id,
      is_active: client.is_active,
      update_channel: client.update_channel,
    };
  };
  return callHasura({ query, variables, transform });
}

export async function deleteConfig(id: string): Promise<void> {
  const query = gql`
    mutation deleteConfig($id: String!) {
      delete_client_by_pk(client_id: $id) {
        client_id
      }
    }
  `;
  const variables = { id };
  const transform = () => {
    /**/
  };
  return callHasura({ query, variables, transform });
}
