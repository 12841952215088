
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "@firebase/auth";
import { defineComponent } from "vue";

import { auth } from "@/utils/firebase-app";
import { Nullable } from "@/utils/ts-utils";

export default defineComponent({
  data() {
    return {
      isForgot: false,
      showPassword: false,
      form: {
        email: "",
        password: "",
      },
      error: null,
      message: null as Nullable<string>,
    };
  },
  methods: {
    submit() {
      signInWithEmailAndPassword(
        auth,
        this.form.email,
        this.form.password
      ).catch((err: any) => {
        this.error = err.message;
        setTimeout(() => (this.error = null), 2500);
      });
    },
    async resetPassword(): Promise<void> {
      this.isForgot = false;
      sendPasswordResetEmail(auth, this.form.email)
        .then(() => {
          this.message =
            "You should receive an email to reset your password soon!";
        })
        .catch((err) => {
          this.error = err.message;
          setTimeout(() => (this.error = null), 2500);
        });

      setTimeout(() => (this.message = null), 2500);
    },
  },
});
