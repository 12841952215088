import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import "@/assets/index.css";
import { onAuthStateChanged } from "@firebase/auth";
import { auth } from "@/utils/firebase-app";
import router from "./router";

onAuthStateChanged(auth, async (user) => {
  console.log("auth changed");
  await store.commit("SET_LOGGED_IN", user !== null);
  if (router.currentRoute.value.name === "login") {
    await router.push("/");
  }
});

createApp(App).use(store).use(router).mount("#app");
