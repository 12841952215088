import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import store from "@/store";
import { getAuthToken } from "@/utils/auth";
import Login from "@/views/Login.vue";
import Apps from "@/views/Apps.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/apps",
    name: "apps",
    component: Apps,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name === "login") {
    next();
    return;
  }
  console.debug("checking");
  const token = await getAuthToken();
  if (!token) {
    console.debug("not authenticated");
    next("/login");
    return;
  }
  console.debug("authenticated");
  if (store.state.isInit) {
    await store.dispatch("init");
  }
  next();
});
export default router;
