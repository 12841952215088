export function gql(query: TemplateStringsArray, ...args: string[]): string {
  const raw = `${query}\n${args.join("\n")}`;
  const whitespaceRe = /[\s]+/g;
  const bracketRe = /[\s]*([{)}(])[\s]*/g;
  return raw.trim().replace(whitespaceRe, " ").replace(bracketRe, "$1");
}

export type GraphQLQuery = {
  query: string;
  variables?: Record<string, any>;
};
