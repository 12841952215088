<template>
  <TopBar />
  <div class="container max-w-screen-md mx-auto">
    <router-view />
    <popup
      v-if="confirmAction"
      :message="confirmAction.message"
      :description="confirmAction.description"
      @close:cancel="closeModal"
      @close:success="confirmAction.func"
    />
  </div>
</template>

<style></style>
<script lang="ts">
import TopBar from "@/components/TopBar.vue";
import Popup from "@/components/Popup.vue";

import { defineComponent } from "vue";
import { mapState } from "vuex";
import store from "@/store";
export default defineComponent({
  components: { Popup, TopBar },
  computed: {
    ...mapState(["confirmAction"]),
  },
  methods: {
    closeModal() {
      store.commit("RESET_CONFIRM_ACTION");
    },
  },
});
</script>
