<template>
  <div class="p-3 border-blue-300 border-2 mt-1 rounded-md">
    <div>
      <chevron-down-icon
        v-if="isExpanded"
        @click="isExpanded = !isExpanded"
        class="h-4 inline-block text-gray-500 mr-2 cursor-pointer"
        aria-hidden="true"
      />
      <chevron-right-icon
        v-else
        @click="isExpanded = !isExpanded"
        class="h-4 inline-block text-gray-500 mr-2 cursor-pointer"
        aria-hidden="true"
      />

      <span
        :class="isActive ? 'text-gray-600' : 'text-gray-400 line-through'"
        class="font-semibold"
        >{{ configName }}</span
      >

      <div class="inline-block float-right">
        <SelectChannel
          class="inline-block"
          :update-channel="config.update_channel"
          @update-channel="setChannel"
        />
        <a :href="download" title="Download">
          <span class="mdi mdi-download text-xl" />
        </a>
        <button
          @click="() => disableConfig(configId)"
          :title="isActive ? 'Disable' : 'Enable'"
        >
          <span
            class="mdi text-xl"
            :class="isActive ? 'mdi-account-off' : 'mdi-account'"
          />
        </button>
        <button @click="() => deleteConfig(configId)" title="Delete">
          <span class="mdi mdi-delete text-xl text-red-500" />
        </button>
      </div>
    </div>
    <span class="text-gray-400 text-sm">{{ configId }}</span>
    <button class="text-xs p-1 text-blue-400" @click="() => copyText(configId)">
      Copy
    </button>
    <div v-show="isExpanded">
      <div class="mt-2" v-for="(group, key) in appGroups" :key="key + '-group'">
        <span class="block text-sm text-gray-500 capitalize">{{ key }}</span>
        <span
          v-for="app in group"
          :key="app"
          class="px-2 py-1 m-1 rounded inline-block"
          :class="hasApp(app) ? 'bg-blue-300' : 'border border-blue-300'"
        >
          <label>
            {{ app.app_name }}
            <input
              class="hidden"
              type="checkbox"
              :id="app"
              :checked="hasApp(app)"
              @input="
                (e) => updateConfigApp(e, { appName: app.app_name, configId })
              "
            />
          </label>
        </span>
        <!--            <input type="text" placeholder="Tag Name" class="lowercase">-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { deleteConfig } from "@/api/hasura";
import { invokeWithConfirmation } from "@/composables/warning";
import { mapState } from "vuex";
import { DokkApp } from "@/models/dokk-app";
import { API_URL } from "@/api/rest";
import { ClientConfig } from "@/models/client";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/vue/outline";
import { UpdateChannels } from "@/store";

import SelectChannel from "@/components/SelectChannel.vue";

export default defineComponent({
  name: "ConfigItem",
  components: {
    SelectChannel,
    ChevronRightIcon,
    ChevronDownIcon,
  },
  props: {
    config: { type: Object as PropType<ClientConfig>, required: true },
    configId: String,
    configName: String,
    isActive: Boolean,
    apps: {
      required: true,
      type: Array as PropType<DokkApp[]>,
    },
    tags: String,
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  emits: ["update-configs", "update-config", "update-app", "disable-config"],
  computed: {
    ...mapState(["allApps"]),
    download() {
      return `${API_URL}/api/config/${this.configId}/download`;
    },
    splitTags() {
      return this.tags?.split(",");
    },
    appGroups(): Record<string, DokkApp[]> {
      return this.allApps.reduce(
        (acc: Record<string, DokkApp[]>, item: DokkApp) => {
          acc[item.app_type] = acc[item.app_type] || [];
          acc[item.app_type].push(item);
          return acc;
        },
        {}
      );
    },
  },
  methods: {
    hasApp(app: DokkApp): boolean {
      return this.apps.findIndex((x) => x.app_name === app.app_name) !== -1;
    },
    updateConfigApp(e: any, { appName, configId }: any) {
      console.log(e.target.checked);
      this.$emit("update-app", { appName, configId, value: e.target.checked });
    },
    setChannel(v: UpdateChannels) {
      const conf = { ...this.config };
      conf.update_channel = v;
      this.updateConfig(conf);
    },
    disableConfig() {
      const conf = { ...this.config };
      conf.is_active = !this.config.is_active;
      this.updateConfig(conf);
    },
    updateConfig(conf: ClientConfig) {
      this.$emit("update-config", conf);
    },
    async deleteConfig(id: string) {
      try {
        await invokeWithConfirmation(
          `Warning`,
          async () => {
            await deleteConfig(id);
            this.$emit("update-configs");
          },
          `Are you sure you want to delete the client ${id}?`
        );
      } catch (e) {
        console.error(e);
      }
    },
    async copyText(x: string) {
      await navigator.clipboard.writeText(x);
    },
  },
});
</script>
