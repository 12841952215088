import { SubscriptionClient } from "subscriptions-transport-ws";
import { getAuthToken } from "@/utils/auth";

const GRAPHQL_URL = process.env.VUE_APP_GRAPHQL_ENDPOINT;

if (!GRAPHQL_URL) {
  throw new Error("graphql endpoint url is missing");
}

export type GraphQLData<T> = {
  query: string;
  transform: (data: any) => T;
  variables?: Record<string, any>;
};

export const ignoreTransform = () => {
  /**/
};

export async function callHasura<T>(gqlData: GraphQLData<T>): Promise<T> {
  if (!gqlData.query) throw new Error("query empty");
  const data = gqlData.variables
    ? { query: gqlData.query, variables: gqlData.variables }
    : { query: gqlData.query };
  const token = await getAuthToken();
  const result = await fetch(GRAPHQL_URL!, {
    method: "post",
    body: JSON.stringify(data),
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  const hasuraData = await result.json();
  if (hasuraData.errors) {
    const errors = hasuraData.errors
      .map((x: any) => JSON.stringify(x, null, " "))
      .join("\n");
    console.error(errors);
    console.log(gqlData);
    throw new Error(
      `calling hasura \n\n Errors: ${errors} \n\n Query: ${JSON.stringify(
        gqlData,
        null,
        " "
      )} `
    );
  }

  return gqlData.transform(hasuraData.data);
}

let SUBSCRIBER: SubscriptionClient;

export function registerSubscriber(
  url = process.env.VUE_APP_GRAPHQL_WS_ENDPOINT /*, token : string*/
): void {
  if (!url) {
    throw new Error("graphql endpoint url is missing");
  }
  SUBSCRIBER = new SubscriptionClient(url, {
    reconnect: true,
    /*    connectionParams: {
       headers: { Authorization: `Bearer ${token}` },
    },*/
  });
}

export type Unsubscriber = () => void;
export type OnSubscribeQuery<T> = (data: T) => void;
export type OnSubscribeComplete = () => void;
export type OnSubscribeError = (e: Error) => void;

export const onSubscribeErrorDefault: OnSubscribeError = (e) =>
  console.error(e);
export const onSubscribeCompleteDefault: OnSubscribeComplete = () =>
  console.log("default message: completed subscription");

export function registerSubscription<T>(
  query: GraphQLData<T>,
  onQuery: OnSubscribeQuery<T>,
  onComplete = onSubscribeCompleteDefault,
  onError = onSubscribeErrorDefault
): Unsubscriber {
  if (!SUBSCRIBER) {
    new Error("SubscriptionClient is not initiated");
  }
  const observable = SUBSCRIBER.request(query);
  const sub = observable.subscribe({
    next: ({ data }: any) => onQuery(query.transform(data)),
    complete: onComplete,
    error: onError,
  });
  return sub.unsubscribe;
}
