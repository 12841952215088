<template>
  <Listbox
    :model-value="updateChannel"
    @update:modelValue="(x) => $emit('update-channel', x)"
  >
    <div class="relative mt-1">
      <ListboxButton
        class="
          relative
          w-40
          py-2
          pl-3
          pr-10
          text-left
          bg-white
          rounded-lg
          shadow-sm
          cursor-default
          focus:outline-none
          focus-visible:ring-2
          focus-visible:ring-opacity-75
          focus-visible:ring-white
          focus-visible:ring-offset-orange-300
          focus-visible:ring-offset-2
          focus-visible:border-indigo-500
          sm:text-sm
        "
      >
        <span class="block truncate capitalize">{{ updateChannel }}</span>
        <span
          class="
            absolute
            inset-y-0
            right-0
            flex
            items-center
            pr-2
            pointer-events-none
          "
        >
          <SelectorIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="
            absolute
            z-10
            w-40
            py-1
            mt-1
            overflow-auto
            text-base
            bg-white
            rounded-md
            shadow-md
            max-h-60
            ring-1 ring-black ring-opacity-5
            focus:outline-none
            sm:text-sm
          "
        >
          <ListboxOption
            v-slot="{ active, selected }"
            v-for="c in ['alpha', 'beta', 'latest']"
            :key="c"
            :value="c"
            as="template"
          >
            <li
              :class="[
                active ? 'text-amber-900 bg-amber-100' : 'text-gray-900',
                'cursor-default select-none relative py-2 pl-10 pr-4',
              ]"
            >
              <span
                class="capitalize"
                :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'block truncate',
                ]"
                >{{ c }}</span
              >
              <span
                v-if="selected"
                class="
                  absolute
                  inset-y-0
                  left-0
                  flex
                  items-center
                  pl-3
                  text-amber-600
                "
              >
                <CheckIcon class="w-5 h-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
<script lang="ts">
import { CheckIcon, SelectorIcon } from "@heroicons/vue/outline";
import { UpdateChannels } from "@/store";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
export default {
  name: "SelectChannel",
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    SelectorIcon,
  },
  props: {
    updateChannel: {
      type: String,
    },
  },
  emits: ["update-channel"],
};
</script>
