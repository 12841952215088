import { DokkApp } from "@/models/dokk-app";

export interface ClientConfig {
  id: string;
  name: string;
  is_active: boolean;
  apps: DokkApp[];
  update_channel: string;
}

export function generateId(name: string): string {
  return name.replaceAll(/\s+/g, "-").replaceAll(/\W/g, "").toUpperCase();
}
