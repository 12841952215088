
import { defineComponent } from "vue";
import {
  addClientApp,
  newConfig,
  removeClientApp,
  updateConfig,
} from "@/api/hasura";
import ConfigItem from "@/components/ConfigItem.vue";
import { ClientConfig } from "@/models/client";
import { mapState } from "vuex";
import store from "@/store";
import { DokkApp } from "@/models/dokk-app";

export default defineComponent({
  name: "Home",
  components: { ConfigItem },
  data() {
    return {
      newConfig: "",
    };
  },
  computed: {
    ...mapState(["allApps", "configs"]),
  },
  methods: {
    async updateApp({ appName, configId, value }: any) {
      const c = this.configs.find((x: ClientConfig) => x.id === configId);
      let isDelete = false;
      if (!c) {
        return;
      }
      const index = c.apps.findIndex((x: DokkApp) => x.app_name === appName);

      if (value && index === -1) {
        c.apps.push({ app_name: appName });
      }

      if (!value && index !== -1) {
        isDelete = true;
        c.apps.splice(index, 1);
      }

      try {
        if (isDelete) {
          await removeClientApp(configId, appName);
        } else {
          await addClientApp(configId, appName);
        }

        await this.refreshConfigs();
      } catch (e) {
        console.error(e);
      }
    },
    async updateConfig(config: ClientConfig) {
      try {
        await updateConfig(config);
      } catch (e) {
        console.error(e);
      }
      await this.refreshConfigs();
    },
    async addConfig() {
      let c;
      try {
        c = await newConfig(this.newConfig);
      } catch (e) {
        console.error(e);
        return;
      }
      await store.dispatch("init");
      this.newConfig = "";
    },
    async refreshConfigs() {
      await store.dispatch("loadConfigs");
    },
  },
});
