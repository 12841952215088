
import { defineComponent, PropType } from "vue";
import { deleteConfig } from "@/api/hasura";
import { invokeWithConfirmation } from "@/composables/warning";
import { mapState } from "vuex";
import { DokkApp } from "@/models/dokk-app";
import { API_URL } from "@/api/rest";
import { ClientConfig } from "@/models/client";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/vue/outline";
import { UpdateChannels } from "@/store";

import SelectChannel from "@/components/SelectChannel.vue";

export default defineComponent({
  name: "ConfigItem",
  components: {
    SelectChannel,
    ChevronRightIcon,
    ChevronDownIcon,
  },
  props: {
    config: { type: Object as PropType<ClientConfig>, required: true },
    configId: String,
    configName: String,
    isActive: Boolean,
    apps: {
      required: true,
      type: Array as PropType<DokkApp[]>,
    },
    tags: String,
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  emits: ["update-configs", "update-config", "update-app", "disable-config"],
  computed: {
    ...mapState(["allApps"]),
    download() {
      return `${API_URL}/api/config/${this.configId}/download`;
    },
    splitTags() {
      return this.tags?.split(",");
    },
    appGroups(): Record<string, DokkApp[]> {
      return this.allApps.reduce(
        (acc: Record<string, DokkApp[]>, item: DokkApp) => {
          acc[item.app_type] = acc[item.app_type] || [];
          acc[item.app_type].push(item);
          return acc;
        },
        {}
      );
    },
  },
  methods: {
    hasApp(app: DokkApp): boolean {
      return this.apps.findIndex((x) => x.app_name === app.app_name) !== -1;
    },
    updateConfigApp(e: any, { appName, configId }: any) {
      console.log(e.target.checked);
      this.$emit("update-app", { appName, configId, value: e.target.checked });
    },
    setChannel(v: UpdateChannels) {
      const conf = { ...this.config };
      conf.update_channel = v;
      this.updateConfig(conf);
    },
    disableConfig() {
      const conf = { ...this.config };
      conf.is_active = !this.config.is_active;
      this.updateConfig(conf);
    },
    updateConfig(conf: ClientConfig) {
      this.$emit("update-config", conf);
    },
    async deleteConfig(id: string) {
      try {
        await invokeWithConfirmation(
          `Warning`,
          async () => {
            await deleteConfig(id);
            this.$emit("update-configs");
          },
          `Are you sure you want to delete the client ${id}?`
        );
      } catch (e) {
        console.error(e);
      }
    },
    async copyText(x: string) {
      await navigator.clipboard.writeText(x);
    },
  },
});
