import { createStore } from "vuex";
import { DokkApp } from "@/models/dokk-app";
import { ClientConfig } from "@/models/client";
import { loadApps, loadConfigs } from "@/api/hasura";
import { Nullable } from "@/utils/ts-utils";
import firebase from "firebase/compat";
import User = firebase.User;

export interface ConfirmAction {
  message: string;
  description?: string;
  func: () => void;
}

export enum UpdateChannels {
  Latest = "latest",
  Alpha = "alpha",
  Beta = "beta",
}

export default createStore({
  state: {
    isInit: true,
    allApps: [] as DokkApp[],
    configs: [] as ClientConfig[],
    confirmAction: null as Nullable<ConfirmAction>,
    user: {
      loggedIn: false,
      data: null as null | User,
    },
  },
  getters: {
    isAuthenticated: (state) => state.user.loggedIn,
  },
  mutations: {
    SET_APPS(state, apps: DokkApp[]) {
      state.allApps = apps;
    },
    SET_CONFIGS(state, configs: ClientConfig[]) {
      state.configs = configs;
    },
    INIT(state) {
      state.isInit = false;
    },
    SET_CONFIRM_ACTION(state, confirmAction: ConfirmAction): void {
      state.confirmAction = confirmAction;
    },
    RESET_CONFIRM_ACTION(state): void {
      state.confirmAction = null;
    },
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
  },
  actions: {
    async init({ dispatch, commit }) {
      await Promise.all([dispatch("loadApps"), dispatch("loadConfigs")]);
      commit("INIT");
    },
    async loadConfigs({ commit }) {
      const configs = await loadConfigs();
      commit("SET_CONFIGS", configs);
    },
    async loadApps({ commit }) {
      const apps = await loadApps();
      commit("SET_APPS", apps);
    },
    setUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", user);
      } else {
        commit("SET_USER", null);
      }
    },
  },
  modules: {},
});
