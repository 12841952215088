
import { CheckIcon, SelectorIcon } from "@heroicons/vue/outline";
import { UpdateChannels } from "@/store";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
export default {
  name: "SelectChannel",
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    SelectorIcon,
  },
  props: {
    updateChannel: {
      type: String,
    },
  },
  emits: ["update-channel"],
};
